import { Home } from 'models';
import { get, post } from '../request';
import { baseApiUrl } from '../../config';
import { HomeImageConfig, HomeState } from 'models';

type PostConfigBody = {
  type: string;
  format: string;
  data: string;
  isUpgrade: boolean;
};

export const homesService = {
  getHomesForDevelopment: async (developmentId: string): Promise<Home[]> => {
    return get<Home[]>(`${baseApiUrl}/developments/${developmentId}/homes`, {});
  },

  postConfigForHome: async (
    developmentId: string,
    homeId: string,
    base64Config: string,
    upgradeConfig: boolean,
  ): Promise<Home> => {
    const body: PostConfigBody = {
      type: 'spreadsheet',
      format: 'xlsx',
      data: base64Config,
      isUpgrade: upgradeConfig,
    };

    return post<Home, PostConfigBody>(
      `${baseApiUrl}/developments/${developmentId}/homes/${homeId}/configuration`,
      body,
    );
  },

  postShadowsForHome: async (developmentId: string, homeId: string): Promise<Home> => {
    return post<Home, null>(
      `${baseApiUrl}/developments/${developmentId}/homes/${homeId}/shadows`,
      null,
    );
  },

  getConfigImageForHome: async (
    developmentId: string,
    homeId: string,
  ): Promise<HomeImageConfig> => {
    return get<HomeImageConfig>(
      `${baseApiUrl}/developments/${developmentId}/homes/${homeId}/images/config/v1`,
    );
  },

  getHomeStatesForDevelopment: async (developmentId: string): Promise<HomeState[]> => {
    return get<HomeState[]>(`${baseApiUrl}/developments/${developmentId}/homes/states`, {});
  },

  getHome: async (developmentId: string, homeId: string): Promise<Home> => {
    return get<Home>(`${baseApiUrl}/developments/${developmentId}/homes/${homeId}`, {});
  },
};

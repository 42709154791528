import { ReactNode, useState, useEffect } from 'react';
import { isAuthenticated, signIn } from '../../../admin-portal/src/authentication/auth.aws';
import { isAwsAuthDebug } from '../config';

export interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const authResult = await isAuthenticated();

      //If the Auth Debug buttons are to be shown, the automatic redirect to login will not occur.
      if (!isAwsAuthDebug() && !authResult) {
        await signIn();
      }
      setUserAuthenticated(authResult);
    })();
  }, [userAuthenticated]);

  return userAuthenticated ? <>{children}</> : <></>;
};

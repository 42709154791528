import { Development } from 'models';
import { get } from '../request';
import { baseApiUrl, countryCode } from '../../config';

export const developmentService = {
  /*
  async getAll(): Promise<Development[] | null> {
    return [
      { createdAt: 'AAA111', name: 'Thorne', city: 'London', country: 'GB' },
      { createdAt: 'BBB222', name: 'Tring', city: 'Manchester', country: 'GB' },
      { createdAt: 'CCC333', name: 'Innovation', city: 'Southend', country: 'GB' },
      { createdAt: 'DDD444', name: 'Moon', city: 'Guildford', country: 'GB' },
    ];
  },*/

  getByDevelopmentId: async (developmentId: string): Promise<Development> => {
    return get<Development>(`${baseApiUrl}/developments/${developmentId}`);
  },

  getAll: async (): Promise<Development[]> => {
    return get<Development[]>(`${baseApiUrl}/developments?country=${countryCode}`);
  },
};

import React from 'react';
import { createRoot } from 'react-dom/client'; // for some reason this is not working when creating project from a template

import { LibraryWrapper } from '@mgh-app/component-library';
import '@mgh-app/component-library/style.css';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider } from '@mui/material/styles';
import { store } from 'store';
import { Provider } from 'react-redux';

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find root element');
}

const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <LibraryWrapper>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <App />
        </Provider>
      </StyledEngineProvider>
    </LibraryWrapper>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const HomeUserStatus = {
  Invited: 'INVITED',
  Unconfirmed: 'UNCONFIRMED',
  Confirmed: 'CONFIRMED',
  ExternalProvider: 'EXTERNAL_PROVIDER',
  Archived: 'ARCHIVED',
  Unknown: 'UNKNOWN',
  ResetRequired: 'RESET_REQUIRED',
  ForceChangePassword: 'FORCE_CHANGE_PASSWORD',
} as const;
export type HomeUserStatus = typeof HomeUserStatus[keyof typeof HomeUserStatus];

export interface HomeUser {
  email: string;
  id?: string;
  authCode?: string;
  status: HomeUserStatus;
  enabled: boolean;
}

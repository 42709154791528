import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import clsx from 'classnames';
import prettyBytes from 'pretty-bytes';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderStyle,
  borderWidth,
  display,
  flexDirection,
  gap,
  gridAutoRows,
  gridTemplateColumns,
  padding,
  textDecoration,
  twCls,
} from 'style';

export interface FileDropzoneProps {
  className?: string;
  onFilesSelected: (files: File[]) => void;
}

export const FileDropzone = ({ className, onFilesSelected }: FileDropzoneProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    onFilesSelected(acceptedFiles);
  }, [acceptedFiles, onFilesSelected]);

  const files = acceptedFiles.map((file) => (
    <li
      key={file.name}
      className={twCls(backgroundColor('bg-grey-300'), borderRadius('rounded'), padding('p-2'))}
    >
      <TextSnippetOutlinedIcon /> <span>{file.name}</span> <span>{prettyBytes(file.size)}</span>
    </li>
  ));

  return (
    <div
      className={clsx(
        'container',
        twCls(
          display('grid'),
          gridTemplateColumns('grid-cols-1'),
          gridAutoRows('auto-rows-min'),
          gap('gap-4'),
        ),
        className,
      )}
    >
      <div
        {...getRootProps({
          className: clsx(
            'dropzone',
            twCls(
              borderWidth('border'),
              borderStyle('border-dashed'),
              borderColor('border-grey-300'),
              padding('p-4'),
              display('flex'),
              flexDirection('flex-col'),
              gap('gap-2'),
              alignItems('items-center'),
            ),
          ),
        })}
      >
        <input {...getInputProps()} />
        <CloudUploadOutlinedIcon />
        <p>
          Drag and drop file, or&nbsp;
          <button type="button" onClick={open} className={twCls(textDecoration('underline'))}>
            browse computer
          </button>
        </p>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </div>
  );
};

import { ApiError } from 'services';

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof ApiError && error.response) {
    return error.message + '<br/>' + error.text;
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return `${error}`;
  }
};

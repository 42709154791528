import { useEffect, useRef } from 'react';

export type UseIntervalHookProps = {
  intervalMS: number;
  identifier: string;
  onIntervalFire: () => void;
  doInitialCallback: boolean; //Do a call before setting initial interval
  logToConsole: boolean;
  canStart: unknown; //Must be true for the interval to start
};

export const useIntervalAsync = (params: UseIntervalHookProps) => {
  const { canStart, doInitialCallback: doInitialCallback, onIntervalFire } = params;
  const active = useRef(false);

  useEffect(() => {
    if (!active.current && canStart) {
      //Do an initial callback before setting up interval
      if (doInitialCallback) onIntervalFireInternal(params);

      //Setup the interval callback
      const interval = setInterval(() => onIntervalFireInternal(params), params.intervalMS);
      active.current = true;
      return () => {
        clearInterval(interval);
        active.current = false;
      };
    }
  }, [canStart, doInitialCallback, onIntervalFire]);
};

const onIntervalFireInternal = async (params: UseIntervalHookProps) => {
  if (params.logToConsole)
    console.log(`IntervalAsync(${params.intervalMS}) - ${params.identifier} - ${new Date()}`);

  params.onIntervalFire(); //Execute the callback
};

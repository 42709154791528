import { ProtectedRoute } from 'authentication/ProtectedRoute';
import { Navigate, useRoutes } from 'react-router-dom';
import { MainLayout } from './layouts/MainLayout';
import { DevelopmentsPage } from './pages/DevelopmentsPage';
import { HomesPage } from './pages/HomesPage';
import { DownloadsPage } from './pages/DownloadsPage';
import { HomeDetailsPage } from 'pages/HomeDetailsPage';

const Router = () => {
  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: (
            <ProtectedRoute>
              <Navigate to="/developments" />
            </ProtectedRoute>
          ),
        },
        {
          path: 'developments',
          element: (
            <ProtectedRoute>
              <DevelopmentsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'developments/:developmentId/homes',
          element: (
            <ProtectedRoute>
              <HomesPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'developments/:developmentId/homes/:homeId',
          element: (
            <ProtectedRoute>
              <HomeDetailsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'downloads',
          element: (
            <ProtectedRoute>
              <DownloadsPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ]);

  return routes;
};

export default Router;

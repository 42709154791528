export class User {
  firstName: string;
  lastName: string;
  email: string;

  constructor(firstName: string, lastName: string, email: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  displayName() {
    return this.firstName || this.lastName ? `${this.firstName} ${this.lastName}` : this.email;
  }

  letterAvatar() {
    return this.firstName.slice(0, 1);
  }

  letterAvatarFromEmail() {
    return this.email.slice(0, 1);
  }
}

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Home } from 'models';
import { authFetchBaseQuery, baseApiUrl } from './apiConfig';

interface GetHomeRequest {
  developmentId: string;
  homeId: string;
}

export const developmentsApi = createApi({
  reducerPath: 'developmentsApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: `${baseApiUrl}/developments`,
  }),
  endpoints: (builder) => ({
    getHome: builder.query<Home, GetHomeRequest>({
      query: ({ developmentId, homeId }) => ({ url: `${developmentId}/homes/${homeId}` }),
    }),
  }),
});

export const { useGetHomeQuery, useLazyGetHomeQuery } = developmentsApi;

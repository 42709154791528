import { useMemo } from 'react';
import { useNavigate } from 'react-router';

export interface UseAppNavigationHook {
  navigateHomes: (developmentId: string) => void;
  navigateHomeDetails: (developmentId: string, homeId: string) => void;
}

export const useAppNavigation = (): UseAppNavigationHook => {
  const navigate = useNavigate();

  return useMemo(
    () => ({
      navigateHomes: (developmentId: string) => navigate(`/developments/${developmentId}/homes`),
      navigateHomeDetails: (developmentId: string, homeId: string) =>
        navigate(`/developments/${developmentId}/homes/${homeId}`),
    }),
    [navigate],
  );
};

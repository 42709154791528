import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { getAccessToken } from 'authentication/auth.aws';

export { baseApiUrl } from '../config';

export const authFetchBaseQuery = (args: Parameters<typeof fetchBaseQuery>[0]) =>
  fetchBaseQuery({
    prepareHeaders: async (headers) => {
      headers.set('Authorization', `Bearer ${await getAccessToken()}`);
      return headers;
    },
    ...args,
  });

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { DataCategory, Document, FileCategory } from 'models';
import { authFetchBaseQuery, baseApiUrl } from './apiConfig';

export interface GetDocumentRequest {
  unitId: string;
}

export interface DocumentsResponse {
  documents: Document[];
}

export interface GetUploadDocumentUrlRequest {
  unitId: string;
  dataCategory: DataCategory;
  fileCategory: FileCategory | string;
  fileName: string;
  documentName: string;
}

export interface GetUploadDocumentUrlResponse {
  url: string;
  fields: Record<string, string>;
}

export const unitsApi = createApi({
  reducerPath: 'unitsApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: `${baseApiUrl}/units`,
  }),
  endpoints: (builder) => ({
    getDocuments: builder.query<DocumentsResponse, GetDocumentRequest>({
      query: ({ unitId }) => ({ url: `${unitId}/documents` }),
    }),
    getUploadUrl: builder.mutation<GetUploadDocumentUrlResponse, GetUploadDocumentUrlRequest>({
      query: ({ unitId, dataCategory, fileCategory, fileName, documentName }) => ({
        url: `${unitId}/documents/${dataCategory}/${fileCategory}`,
        method: 'POST',
        body: {
          fileName,
          documentName,
        },
      }),
    }),
  }),
});

export const uploadDocument = (signedPostUrl: GetUploadDocumentUrlResponse, file: File) => {
  const formData = new FormData();
  Object.entries(signedPostUrl.fields).forEach((entry) => formData.append(entry[0], entry[1]));
  formData.append('file', file);

  return fetch(signedPostUrl.url, { method: 'POST', body: formData });
};

export const { useGetDocumentsQuery, useLazyGetDocumentsQuery, useGetUploadUrlMutation } = unitsApi;

import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { justifyContent, margin, twCls, dialogTitleStyle, dialogContentStyle } from 'style';

export const ProcessingDialogState = {
  Busy: 'Busy',
  Error: 'Error',
  Success: 'Success',
} as const;
export type ProcessingDialogState =
  typeof ProcessingDialogState[keyof typeof ProcessingDialogState];

export interface ProcessingDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  state: ProcessingDialogState;
  message?: string;
}

export const ProcessingDialog = ({
  open,
  onClose,
  title,
  description,
  state,
  message,
}: ProcessingDialogProps): JSX.Element => {
  return (
    <Dialog open={open}>
      <DialogTitle className={dialogTitleStyle}>{title}</DialogTitle>
      <DialogContent className={dialogContentStyle}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
        {state == ProcessingDialogState.Error && message && (
          <Alert severity="error" className={twCls(margin('mt-6'))}>
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </Alert>
        )}
        {state == ProcessingDialogState.Success && message && (
          <Alert severity="success" className={twCls(margin('mt-6'))}>
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </Alert>
        )}
      </DialogContent>
      <DialogActions className={twCls(justifyContent('justify-between'))}>
        <span>{state == ProcessingDialogState.Busy && <CircularProgress />}</span>
        <Button disabled={state == ProcessingDialogState.Busy} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';
import Button from '@mui/material/Button';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderStyle,
  borderWidth,
  combineCls,
  display,
  fontSize,
  height,
  justifyContent,
  margin,
  padding,
  textColor,
  textTransform,
  twCls,
  width,
} from 'style';

const buttonBaseStyle = twCls(
  margin('my-2'),
  borderRadius('rounded'),
  padding('p-4'),
  textTransform('normal-case'),
);

const primaryButtonStyle = combineCls(
  buttonBaseStyle,
  twCls(
    backgroundColor('bg-tmp-blue-4', 'hover:bg-tmp-blue-4'),
    borderStyle('border-solid'),
    borderWidth('border'),
    borderColor('border-tmp-blue-4', 'hover:border-tmp-blue-4'),
    textColor('text-white', 'hover:text-black'),
    margin('mr-4'),
  ),
);

const secondaryButtonStyle = combineCls(
  buttonBaseStyle,
  twCls(
    backgroundColor('bg-white'),
    borderStyle('border-solid'),
    borderWidth('border'),
    borderColor('border-tmp-blue-4'),
    textColor('text-tmp-blue-4'),
    margin('ml-4'),
  ),
);

const tertiaryButtonStyle = combineCls(
  buttonBaseStyle,
  twCls(
    backgroundColor('bg-white'),
    borderStyle('border-solid'),
    borderWidth('border'),
    borderColor('border-tmp-red-1'),
    textColor('text-tmp-red-1'),
    margin('mr-4'),
  ),
);

const partStyle = twCls(display('flex'), width('w-1/2'));

export interface HeaderProps {
  title: string;
  primaryButtonCaption?: string;
  primaryButtonClick?: React.MouseEventHandler;
  secondaryButtonCaption?: string;
  secondaryButtonClick?: React.MouseEventHandler;
  tertiaryButtonCaption?: string;
  tertiaryButtonClick?: React.MouseEventHandler;
}

export const Header = ({
  title,
  primaryButtonCaption,
  primaryButtonClick,
  secondaryButtonCaption,
  secondaryButtonClick,
  tertiaryButtonCaption,
  tertiaryButtonClick,
}: HeaderProps): JSX.Element => {
  const primaryButton =
    primaryButtonCaption != null ? (
      <Button className={primaryButtonStyle} onClick={primaryButtonClick}>
        {primaryButtonCaption}
      </Button>
    ) : (
      <div />
    );

  const secondaryButton =
    secondaryButtonCaption != null ? (
      <Button className={secondaryButtonStyle} onClick={secondaryButtonClick}>
        {secondaryButtonCaption}
      </Button>
    ) : (
      <div />
    );

  const tertiaryButton =
    tertiaryButtonCaption != null ? (
      <Button className={tertiaryButtonStyle} onClick={tertiaryButtonClick}>
        {tertiaryButtonCaption}
      </Button>
    ) : (
      <div />
    );

  return (
    <div className={twCls(display('flex'), height('h-16'), backgroundColor('bg-white'))}>
      <div className={partStyle}>{secondaryButton}</div>
      <div
        className={combineCls(
          twCls(justifyContent('justify-center'), width('w-9/12'), display('flex')),
        )}
      >
        <span className={twCls(margin('mt-3'), textColor('text-tmp-blue-1'), fontSize('text-2xl'))}>
          {title}
        </span>
      </div>
      <div className={combineCls(partStyle, twCls(justifyContent('justify-end')))}>
        {tertiaryButton}
        {primaryButton}
      </div>
    </div>
  );
};

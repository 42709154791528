import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { dialogTitleStyle, dialogContentStyle } from 'style';

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  description: string;
  acceptText?: string;
  rejectText?: string;
  onButtonClick: (accepted: boolean) => void;
}

export const ConfirmationDialog = ({
  open,
  title,
  description,
  acceptText,
  rejectText,
  onButtonClick,
}: ConfirmationDialogProps): JSX.Element => {
  const onAcceptClick = () => {
    onButtonClick(true);
  };

  const onRejectClick = () => {
    onButtonClick(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle className={dialogTitleStyle}>{title}</DialogTitle>
      <DialogContent className={dialogContentStyle}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onAcceptClick}>{acceptText ? acceptText : 'Ok'}</Button>
        <Button onClick={onRejectClick}>{rejectText ? rejectText : 'Cancel'}</Button>
      </DialogActions>
    </Dialog>
  );
};

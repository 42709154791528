import {
  twCls,
  margin,
  propertyTableStyle,
  propertyTableLabelStyle,
  propertyTableValueStyle,
  propertyTableStyleHeader,
  dataCardStyle,
} from 'style';
import Button from '@mui/material/Button';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';

export interface DevelopmentListItemProps {
  developmentId: string;
  name: string;
  city: string;
  country: string;
  onClick: () => void;
}
export const DevelopmentListItem = ({
  developmentId,
  name,
  city,
  country,
  onClick,
}: DevelopmentListItemProps): JSX.Element => {
  return (
    <div className={dataCardStyle}>
      <div className={propertyTableStyleHeader}>
        {name} [{developmentId}]
      </div>

      <table className={propertyTableStyle}>
        <tbody>
          <tr>
            <td className={propertyTableLabelStyle}>City</td>
            <td className={propertyTableValueStyle}>{city}</td>
          </tr>
          <tr>
            <td className={propertyTableLabelStyle}>Country</td>
            <td className={propertyTableValueStyle}>{country}</td>
          </tr>
        </tbody>
      </table>

      <div className={twCls(margin('ml-2'))}>
        <Button onClick={onClick}>
          <HolidayVillageIcon className={margin('mr-1')} />
          View Homes
        </Button>
      </div>
    </div>
  );
};

import { getAccessToken } from 'authentication/auth.aws';

export interface ErrorBase {
  message: string;
  stack?: string;
  causes?: CauseDetail[];
}

export interface CauseDetail {
  reason: string;
  params?: unknown;
  path?: string;
}

export interface ErrorResponse<TErrorData> extends ErrorBase {
  data: TErrorData;
}

export class ApiError<TError> extends Error {
  response?: ErrorResponse<TError>;
  text?: string;

  constructor(message?: string, response?: ErrorResponse<TError>) {
    super(message);
    this.response = response;
    if (response) this.text = getCauses(response);
  }
}

export function getCauses(errorResponse: ErrorResponse<unknown>): string {
  let causes = '';

  if (errorResponse.causes) {
    for (let index = 0; index < errorResponse.causes.length; index++) {
      const causeDetail = errorResponse.causes[index] as CauseDetail;
      causes += `<br/>REASON: ${causeDetail.reason}`;

      if (causeDetail.params) {
        causes += `<br/>PARAMS: ${JSON.stringify(causeDetail.params)}<br/>`;
      }
    }
  }
  return causes;
}

export async function requestNew<TResponse, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse> {
  const response = await fetch(url, config);
  const data = await response.json();

  if (response.status >= 400) {
    throw new ApiError(data.message, data as ErrorResponse<TError>);
    /**
     * NOTE: response.statusText seems to be blank.
     * According to HTTP/2 standards, it will always be returned as blank
     */
    //throw new ApiError(response.statusText, data as ErrorResponse<TError>);
  }

  return data as TResponse;
}

export const authRequest = async <TResponse, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse> => {
  const token = await getAccessToken();

  return requestNew<TResponse, TError>(url, {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    },
  });
};

export const get = async <TResponse, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse> =>
  authRequest<TResponse, TError>(url, {
    method: 'GET',
    ...config,
    headers: {
      ...config.headers,
    },
  });

export const post = async <TResponse, TRequest, TError = unknown>(
  url: string,
  body: TRequest,
  config: RequestInit = {},
): Promise<TResponse> =>
  authRequest<TResponse, TError>(url, {
    method: 'POST',
    body: body ? JSON.stringify(body) : '',
    ...config,
    headers: Object.assign(
      body ? { 'Content-Type': 'application/json' } : {},
      config.headers,
    ),
  });

import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoUserSession, CognitoIdToken } from 'amazon-cognito-identity-js';
import {
  awsAuthClientId,
  awsAuthDomain,
  awsAuthRegion,
  awsAuthScopes,
  awsAuthSignInUrl,
  awsAuthSignOutUrl,
  awsAuthUserPoolId,
} from '../config';

const cognitoDomain = `${awsAuthDomain}.auth.${awsAuthRegion}.amazoncognito.com`;

export const configure = () => {
  if (isLocalMode()) {
    return;
  }

  Amplify.configure({
    Auth: {
      region: `${awsAuthRegion}`,
      userPoolId: `${awsAuthUserPoolId}`,
      userPoolWebClientId: `${awsAuthClientId}`,
      mandatorySignIn: true,
      oauth: {
        domain: cognitoDomain,
        scope: awsAuthScopes.split(','),
        redirectSignIn: `${awsAuthSignInUrl}`,
        redirectSignOut: `${awsAuthSignOutUrl}`,
        responseType: 'code',
      },
    },
  });
};

export type AuthResult = {
  isError: boolean;
  message: string;
};

export const getConfigDetails = () => {
  const details: string =
    `<strong>region:</strong> ${awsAuthRegion}<br/>` +
    `<strong>userPoolId:</strong> ${awsAuthUserPoolId}<br/>` +
    `<strong>userPoolWebClientId:</strong> ${awsAuthClientId}<br/>` +
    `<strong>domain:</strong> ${cognitoDomain}<br/>` +
    `<strong>scopes:</strong> ${awsAuthScopes}<br/>` +
    `<strong>redirectSignIn:</strong> ${awsAuthSignInUrl}<br/>` +
    `<strong>redirectSignOut:</strong> ${awsAuthSignOutUrl}<br/>`;
  return details;
};

export const listen = (setAuthResult: (authResult: AuthResult) => void) => {
  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn':
      case 'cognitoHostedUI':
        const authResultSignIn: AuthResult = {
          isError: false,
          message: 'Sign-in successful',
        };
        console.log(authResultSignIn);
        setAuthResult(authResultSignIn);
        break;

      case 'oAuthSignOut':
      case 'signOut':
        const authResultSignOut: AuthResult = {
          isError: false,
          message: 'Sign-out successful',
        };
        console.log(authResultSignOut);
        setAuthResult(authResultSignOut);
        break;

      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        const authResultFailure: AuthResult = {
          isError: true,
          message: data,
        };
        console.error(authResultFailure);
        setAuthResult(authResultFailure);
        break;
    }
  });
};

export const getAccessToken = async (): Promise<string> => {
  if (isLocalMode()) {
    return '';
  }

  const currentSession: CognitoUserSession = await Auth.currentSession();
  return currentSession.getAccessToken().getJwtToken();
};

export const getIdToken = async (): Promise<CognitoIdToken> => {
  const currentSession: CognitoUserSession = await Auth.currentSession();
  return currentSession.getIdToken();
};

export const checkUser = async () => {
  return Auth.currentAuthenticatedUser();
};

export const signOut = async () => {
  return Auth.signOut();
};

export const signIn = async () => {
  return Auth.federatedSignIn();
};

export const getUserEmail = async (): Promise<string> => {
  if (isLocalMode()) {
    return 'testUser@test.com';
  }
  const currentIdToken = await getIdToken();
  return currentIdToken.payload['email'];
};

const isLocalMode = () => process.env.NODE_ENV === 'development';

export const isAuthenticated = async (): Promise<boolean> => {
  try {
    if (isLocalMode()) {
      return true;
    }

    await checkUser();
    return true;
  } catch {
    return false;
  }
};

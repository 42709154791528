import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { developmentsApi } from 'services/developmentsApi';
import { unitsApi } from 'services/unitsApi';

export const store = configureStore({
  reducer: {
    [developmentsApi.reducerPath]: developmentsApi.reducer,
    [unitsApi.reducerPath]: unitsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(developmentsApi.middleware, unitsApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

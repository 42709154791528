import { useEffect } from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastKind = {
  Info: 'Info',
  Success: 'Success',
  Error: 'Error',
  Warning: 'Warning',
  Default: 'Default',
} as const;
export type ToastKind = typeof ToastKind[keyof typeof ToastKind];

export interface ToastProps {
  toastKind: ToastKind;
  message: string | (() => JSX.Element);
  toastId?: string;
}

export const Toast = ({ toastKind, message, toastId }: ToastProps): JSX.Element => {
  useEffect(() => {
    const options: ToastOptions = {};
    if (toastKind == ToastKind.Error) options.autoClose = false; //Note: TRUE is not a valid value here
    if (toastId) options.toastId = toastId;

    if (message) {
      if (toastKind == ToastKind.Info) toast.info(message, options);
      else if (toastKind == ToastKind.Error) toast.error(message, options);
      else if (toastKind == ToastKind.Success) toast.success(message, options);
      else if (toastKind == ToastKind.Warning) toast.warning(message, options);
      else toast(`${message}`);
    }
  }, [toastKind, message, toastId]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={2500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover
      theme="colored"
    />
  );
};

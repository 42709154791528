import { Menu, MenuItem } from '@mui/material';
import { navPages } from 'components/Sidebar';
import { MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { combineCls, fontWeight, padding, twCls } from 'style';
import { ReactComponent as HamburgerMenuIcon } from '../../assets/hamburgerMenu.svg';

const activeRootLinkClassName = twCls(fontWeight('font-bold'));
const setActiveRootLinkClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? activeRootLinkClassName : '';

const navChildLinkClassName = twCls(padding('pl-6'));
const activeChildLinkClassName = combineCls(navChildLinkClassName, twCls(fontWeight('font-bold')));
const setActiveChildLinkClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? activeChildLinkClassName : navChildLinkClassName;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DropdownMenuProps {}

export const DropdownMenu = ({}: DropdownMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button aria-label="menu dropdown" onClick={handleClick}>
        <HamburgerMenuIcon />
      </button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {navPages.map((page, i) => (
          <MenuItem key={i} onClick={handleClose}>
            <NavLink
              key={page.key}
              to={page.path}
              className={page.isRoot ? setActiveRootLinkClassName : setActiveChildLinkClassName}
              end={true}
            >
              {page.pageDisplayName}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Checkbox,
} from '@mui/material';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { InviteHomeUserDialog } from 'components/InviteHomeUserDialog';
import { Toast, ToastKind, ToastProps } from 'components/Toast';
import { Home } from 'models';
import { HomeUser } from 'models/homeUser';
import { useEffect, useState } from 'react';
import { usersService } from 'services/usersService';
import {
  dialogContentStyle,
  dialogTitleStyle,
  justifyContent,
  margin,
  twCls,
  textAlign,
  whitespace,
  height,
} from 'style';
import { getErrorMessage } from 'utils/errorUtils';
import { toPascalCase } from 'utils/stringUtils';
import { HomeUserStatus } from '../../models/homeUser';
import { HomeUserAction } from '../../services/usersService/usersService';

export interface ManageHomeUsersDialogProps {
  open: boolean;
  onClose: () => void;
  home: Home;
}

const DialogState = {
  Idle: 'Idle',
  Busy: 'Busy',
  Error: 'Error',
} as const;
type DialogState = typeof DialogState[keyof typeof DialogState];

export const ManageHomeUsersDialog = ({
  open,
  onClose,
  home,
}: ManageHomeUsersDialogProps): JSX.Element => {
  const [doInitialLoadUsers, setDoInitialLoadUsers] = useState<boolean>(true);
  const [toastProps, setToastProps] = useState<ToastProps>();
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.Idle);
  const [error, setError] = useState<string>();
  const [users, setUsers] = useState<HomeUser[]>();
  const [selectedUser, setSelectedUser] = useState<HomeUser>();
  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
  const [showDeleteUser, setShowDeleteUser] = useState<boolean>(false);

  const handleInviteUserClick = async () => {
    setShowInviteUser(true);
  };

  const handleInviteClose = async () => {
    setShowInviteUser(false);
  };

  const handleUserInvited = async (user: HomeUser) => {
    setSelectedUser(user);
    loadUsers(home.developmentId, home.homeId);
  };

  const handleResendInvite = async (user: HomeUser) => {
    setSelectedUser(user);
    resendUserInvite(home.developmentId, home.homeId, user.email);
  };

  const handleUserUpdateClick = async (user: HomeUser, mustEnable: boolean) => {
    setSelectedUser(user);
    updateUser(home.developmentId, home.homeId, user.email, mustEnable);
  };

  const handleUserDeleteClick = async (user: HomeUser) => {
    setSelectedUser(user);
    setShowDeleteUser(true);
  };

  const handleUserDeleteConfirmClick = async (accepted: boolean) => {
    if (!selectedUser) return;
    if (accepted) deleteUser(home.developmentId, home.homeId, selectedUser.email);
    setShowDeleteUser(false);
  };

  const loadUsers = async (developmentId: string, homeId: string) => {
    setDialogState(DialogState.Busy);
    try {
      const homeUsers = await usersService.getUserForHome(developmentId, homeId);
      setUsers(homeUsers);
      setDialogState(DialogState.Idle);
    } catch (error) {
      setDialogState(DialogState.Error);
      setError(`Failed to load users.  ${getErrorMessage(error)}`);
    }
  };

  const updateUser = async (
    developmentId: string,
    homeId: string,
    email: string,
    enabled: boolean,
  ) => {
    setDialogState(DialogState.Busy);
    try {
      const userAction: HomeUserAction = enabled ? HomeUserAction.Enable : HomeUserAction.Disable;
      await usersService.postUpdateUser(developmentId, homeId, email, userAction, undefined);

      //Create the HTML message (JSX Element) to be toasted
      const messageComponent = () => (
        <>
          <strong>{email}</strong> {enabled ? 'enabled' : 'disabled'} successfully.
        </>
      );

      setToastProps({
        message: messageComponent,
        toastKind: ToastKind.Success,
        toastId: `delete-user-${email}-${new Date().getTime()}`, //Use ToastId to prevent duplicates
      });

      //Reload the users
      const homeUsers = await usersService.getUserForHome(developmentId, homeId);
      setUsers(homeUsers);

      setDialogState(DialogState.Idle);
    } catch (error) {
      setDialogState(DialogState.Error);
      setError(`Failed to update user.  ${getErrorMessage(error)}`);
      return;
    }
  };

  const deleteUser = async (developmentId: string, homeId: string, email: string) => {
    setDialogState(DialogState.Busy);
    try {
      await usersService.postUpdateUser(
        developmentId,
        homeId,
        email,
        HomeUserAction.Delete,
        undefined,
      );

      //Create the HTML message (JSX Element) to be toasted
      const messageComponent = () => (
        <>
          <strong>{email}</strong> deleted successfully.
        </>
      );

      setToastProps({
        message: messageComponent,
        toastKind: ToastKind.Success,
        toastId: `delete-user-${email}-${new Date().getTime()}`, //Use ToastId to prevent duplicates
      });

      //Reload the users
      const homeUsers = await usersService.getUserForHome(developmentId, homeId);
      setUsers(homeUsers);

      setDialogState(DialogState.Idle);
    } catch (error) {
      setDialogState(DialogState.Error);
      setError(`Failed to delete user.  ${getErrorMessage(error)}`);
      return;
    }
  };

  const resendUserInvite = async (developmentId: string, homeId: string, email: string) => {
    setDialogState(DialogState.Busy);
    try {
      await usersService.postInviteUserToHome(home.developmentId, home.homeId, email, true);

      //Create the HTML message (JSX Element) to be toasted
      const messageComponent = () => (
        <>
          Invite resent to <strong>{email}</strong> successfully.
        </>
      );

      //Successful toast
      setToastProps({
        message: messageComponent,
        toastKind: ToastKind.Success,
        toastId: `invite-user-${email}-${new Date().getTime()}`, //Use ToastId to prevent duplicates
      });

      setDialogState(DialogState.Idle);
    } catch (error) {
      setDialogState(DialogState.Error);
      setError(`Failed to resend invite.  ${getErrorMessage(error)}`);
      return;
    }
  };

  //Reset the states on dialog open
  useEffect(() => {
    if (open) {
      setDialogState(DialogState.Idle);
      setError(undefined);
      setUsers(undefined);
      setToastProps(undefined);
      setShowInviteUser(false);
      setSelectedUser(undefined);
      setShowDeleteUser(false);
      setDoInitialLoadUsers(true);
    }
  }, [open]);

  //Load the users for a home
  useEffect(() => {
    //if (users) return; //Only reload if users empty
    if (!doInitialLoadUsers) return;
    loadUsers(home.developmentId, home.homeId);
    setDoInitialLoadUsers(false);
  }, [home, doInitialLoadUsers]);

  return (
    <>
      {toastProps && (
        <Toast
          message={toastProps.message}
          toastKind={toastProps.toastKind}
          toastId={toastProps.toastId}
        />
      )}

      <Dialog open={open} fullWidth={false} maxWidth={'md'}>
        <DialogTitle className={dialogTitleStyle} sx={{ minWidth: 350 }}>
          Manage Users for <strong>{home.name}</strong>
        </DialogTitle>
        <DialogContent className={dialogContentStyle}>
          {users && users.length == 0 && (
            <div className={twCls(textAlign('text-center'))}>No users to display.</div>
          )}
          {dialogState == DialogState.Error && error && (
            <Alert severity="error" className={twCls(margin('mb-6'))}>
              <span dangerouslySetInnerHTML={{ __html: error }} />
            </Alert>
          )}
          {users && users.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Enabled</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow
                        key={user.email}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {user.email}
                        </TableCell>
                        <TableCell>{toPascalCase(user.status)}</TableCell>
                        <TableCell>
                          <Checkbox disabled checked={user.enabled} color="default" />
                        </TableCell>
                        <TableCell className={whitespace('whitespace-nowrap')}>
                          <div>
                            {user.status == HomeUserStatus.Invited && (
                              <Button
                                disabled={dialogState == DialogState.Busy}
                                onClick={async () => {
                                  handleResendInvite(user);
                                }}
                              >
                                Resend
                              </Button>
                            )}
                            {user.enabled && user.status != HomeUserStatus.Invited && (
                              <Button
                                disabled={dialogState == DialogState.Busy}
                                onClick={async () => {
                                  handleUserUpdateClick(user, false);
                                }}
                              >
                                Disable
                              </Button>
                            )}
                            {!user.enabled && user.status != HomeUserStatus.Invited && (
                              <Button
                                disabled={dialogState == DialogState.Busy}
                                onClick={async () => {
                                  handleUserUpdateClick(user, true);
                                }}
                              >
                                Enable
                              </Button>
                            )}
                            <Button
                              disabled={dialogState == DialogState.Busy}
                              onClick={async () => {
                                handleUserDeleteClick(user);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions className={twCls(justifyContent('justify-between'), height('h-14'))}>
          <div>{dialogState == DialogState.Busy && <CircularProgress />}</div>
          <div>
            <Button disabled={dialogState == DialogState.Busy} onClick={handleInviteUserClick}>
              Invite User
            </Button>
            <Button disabled={dialogState == DialogState.Busy} onClick={onClose}>
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <InviteHomeUserDialog
        open={showInviteUser}
        home={home}
        onClose={handleInviteClose}
        onUserInvited={handleUserInvited}
      />

      <ConfirmationDialog
        open={showDeleteUser && selectedUser != undefined}
        title={'Delete User'}
        description={`Are you sure you want to delete <strong>${selectedUser?.email}</strong>?`}
        onButtonClick={handleUserDeleteConfirmClick}
        acceptText={'Yes'}
        rejectText={'No'}
      />
    </>
  );
};

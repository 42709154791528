export const getDateTimeString = (date: Date) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#parameters

  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    //weekday: 'long',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
    second: '2-digit',
  });
};

import { Alert, AlertColor, Button } from '@mui/material';
import { useEffect, useCallback, useState } from 'react';
import { margin, twCls } from 'style';
import { getConfigDetails } from '../../authentication/auth.aws';

import {
  checkUser,
  getAccessToken,
  signIn,
  signOut,
  isAuthenticated,
  getUserEmail,
  AuthResult,
} from 'authentication/auth.aws';

export interface AuthenticationButtonsProps {
  authResult: AuthResult | undefined;
}

export const AuthenticationButtons = ({ authResult }: AuthenticationButtonsProps): JSX.Element => {
  const [message, setMessage] = useState<string>();
  const [alertType, setAlertType] = useState<AlertColor>('info');

  useEffect(() => {
    if (authResult) {
      setMessage(authResult.message);
      authResult.isError ? setAlertType('error') : setAlertType('success');
    }
  }, [authResult]);

  const signInClick = () => {
    (async () => {
      await signIn();
    })();
  };

  const signOutClick = () => {
    (async () => {
      await signOut();
    })();
  };

  const getTokenClick = useCallback(() => {
    setMessage(undefined);
    (async () => {
      try {
        const token = await getAccessToken();
        setAlertType('success');
        setMessage(`${token}`);
      } catch (error) {
        setAlertType('error');
        setMessage(`${error}`);
      }
    })();
  }, []);

  const checkUserClick = useCallback(() => {
    setMessage(undefined);
    (async () => {
      try {
        await checkUser();
        setAlertType('success');
        setMessage('User authenticated');
      } catch (error) {
        setAlertType('error');
        setMessage(`${error}`);
      }
    })();
  }, []);

  const isAuthenticatedClick = useCallback(() => {
    setMessage(undefined);
    (async () => {
      const authenticated = await isAuthenticated();
      setAlertType('success');
      setMessage(`${authenticated}`);
    })();
  }, []);

  const getUserDetailsClick = useCallback(() => {
    setMessage(undefined);
    (async () => {
      try {
        const email = await getUserEmail();
        setAlertType('success');
        setMessage(`Email: ${email}`);
      } catch (error) {
        setAlertType('error');
        setMessage(`${error}`);
      }
    })();
  }, []);

  return (
    <div className={twCls(margin('mt-6'))}>
      <br />
      <br />
      <p>
        <strong>AWS Cognito DEBUG enabled! Automatic redirect to sign in in disabled.</strong>
      </p>
      <br />
      <p>
        Set the <strong>AWSAUTH_DEBUG</strong> to <strong>false</strong> inside the .env file during
        build and deployment to remove.
      </p>
      <br />
      <p>
        <span dangerouslySetInnerHTML={{ __html: getConfigDetails() }} />
      </p>
      <br />
      <Button onClick={signInClick}>Sign In</Button>
      <Button onClick={signOutClick}>Sign Out</Button>
      <Button onClick={isAuthenticatedClick}>Is Authenticated</Button>
      <Button onClick={checkUserClick}>Check User</Button>
      <Button onClick={getTokenClick}>Get Token</Button>
      <Button onClick={getUserDetailsClick}>Get User Details</Button>
      {message && (
        <Alert severity={alertType} className={twCls(margin('mt-6'))}>
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </Alert>
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';
import { Header } from '../components/Header/Header';
import { twCls, emptySpaceStyle, textAlign, padding, margin, width } from 'style';
import { Alert, CircularProgress } from '@mui/material';

import { Development } from 'models';
import { developmentService } from '../services/developmentsService/developmentsService';
import { DevelopmentList } from '../components/DevelopmentList';
import { getErrorMessage } from 'utils/errorUtils';

export const DevelopmentsPage = () => {
  const [developments, setDevelopments] = useState<Development[] | null>(null);
  const [error, setError] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await developmentService.getAll();
        setDevelopments(response);
        setIsError(false);
      } catch (error) {
        setError(getErrorMessage(error));
        setIsError(true);
      }
    })();
  }, []);

  const developmentsListControl =
    developments != null && developments.length > 0 ? (
      <DevelopmentList developments={developments} />
    ) : (
      <div className={emptySpaceStyle}>No developments to display.</div>
    );

  const loadingErrorControl = isError ? (
    <div>
      <Alert severity="error" className={twCls(margin('mt-6'), margin('m-auto'), width('w-4/5'))}>
        <span dangerouslySetInnerHTML={{ __html: error }} />
      </Alert>
    </div>
  ) : (
    <div className={twCls(textAlign('text-center'), padding('pt-5'))}>
      <CircularProgress />
    </div>
  );

  return (
    <div>
      <Header title="Developments" />
      {developments ? developmentsListControl : loadingErrorControl}
    </div>
  );
};

import { BrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Router from './routes';
import { configure, listen, AuthResult } from './authentication/auth.aws';
import { AuthenticationButtons } from './components/AuthenticationButtons/AuthenticationButtons';
import { Toast, ToastKind } from 'components/Toast';
import { isAwsAuthDebug } from 'config';

function App() {
  const [authResult, setAuthResult] = useState<AuthResult>();
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastKind, setToastKind] = useState<ToastKind>(ToastKind.Default);

  //NOTE: Must be set here outside useEffect().
  configure();

  //Configure Authentication, and listen for authentication callbacks
  useEffect(() => {
    listen(setAuthResult);
  }, []);

  //Handles the toasts due to auth events
  useEffect(() => {
    if (authResult) {
      setToastMessage(authResult.message);
      authResult.isError ? setToastKind(ToastKind.Error) : setToastKind(ToastKind.Success);
    }
  }, [authResult]);

  return (
    <BrowserRouter>
      <Router />
      {isAwsAuthDebug() && <AuthenticationButtons authResult={authResult} />}
      {authResult && <Toast message={toastMessage} toastKind={toastKind} />}
    </BrowserRouter>
  );
}

export default App;

import { useEffect, useState, useCallback } from 'react';
import { Header } from '../components/Header/Header';
import {
  twCls,
  emptySpaceStyle,
  textAlign,
  padding,
  margin,
  width,
  dataCardFooterStyle,
} from 'style';
import { useParams } from 'react-router-dom';
import { Alert, CircularProgress } from '@mui/material';

import { HomeList } from '../components/HomeList';
import { Home, Development, isHomeStateUpdated, updateHomeState } from 'models';
import { homesService, developmentService } from '../services';
import { getErrorMessage } from 'utils/errorUtils';
import { getDateTimeString } from 'utils/DateTimeUtils';
import { useIntervalAsync, UseIntervalHookProps } from 'hooks/useIntervalAsync';

export const HomesPage = () => {
  const { developmentId } = useParams();

  const [homes, setHomes] = useState<Home[] | null>(null);
  const [development, setDevelopment] = useState<Development | null>(null);
  const [error, setError] = useState<string>();
  const [lastRefreshed, setLastRefreshed] = useState<Date>(new Date());

  const updateHomeCallback = useCallback(
    (updatedHome: Home) => {
      if (!homes) return;

      const draftHomes: Home[] = homes.map((home) => {
        return updatedHome.uuid == home.uuid ? updatedHome : home;
      });

      setHomes(draftHomes);
    },
    [homes],
  );

  useEffect(() => {
    (async () => {
      if (!developmentId) return;

      try {
        //Get the development specified
        const developmentResponse = await developmentService.getByDevelopmentId(developmentId);
        setDevelopment(developmentResponse);

        //Get the homes for the development
        const homesResponse = await homesService.getHomesForDevelopment(developmentId);
        setHomes(homesResponse);
        setLastRefreshed(new Date());
      } catch (error) {
        setError(getErrorMessage(error));
      }
    })();
  }, [developmentId]);

  const refreshHomes = useCallback(async () => {
    if (!homes) return;
    if (!developmentId) return;

    try {
      const draftHomes: Home[] = [...homes];
      let homesUpdated = false;

      //Load the latest states for all homes on development
      const homeStates = await homesService.getHomeStatesForDevelopment(developmentId);
      setLastRefreshed(new Date());

      //Update the home when necessary
      for (let index = 0; index < homeStates.length; index++) {
        const homeState = homeStates[index];
        const foundHome = draftHomes?.find((home) => home.homeId == homeState.homeId);

        //This should never happen, but just handle gracefully
        if (!foundHome) continue;

        //Check for changes, and update if changed
        if (isHomeStateUpdated(foundHome, homeState)) {
          homesUpdated = true; //Flag use to show if change occurred so that selective UI refresh is performed
          updateHomeState(foundHome, homeState);
        }
      }

      //An update occurred, so now perform a UI refresh
      if (homesUpdated) {
        setHomes(draftHomes);
      }
    } catch (error) {
      console.error(getErrorMessage(error));
    }
  }, [homes, developmentId]);

  const homesListControl =
    homes != null && homes.length > 0 ? (
      <div>
        <HomeList homes={homes} updateHomeCallback={updateHomeCallback} />
        <div className={dataCardFooterStyle}>
          Last refreshed: {getDateTimeString(lastRefreshed)}
        </div>
      </div>
    ) : (
      <div className={emptySpaceStyle}>No homes to display.</div>
    );

  const loadingErrorControl = error ? (
    <div>
      <Alert severity="error" className={twCls(margin('mt-6'), margin('m-auto'), width('w-4/5'))}>
        <span dangerouslySetInnerHTML={{ __html: error }} />
      </Alert>
    </div>
  ) : (
    <div className={twCls(textAlign('text-center'), padding('pt-5'))}>
      <CircularProgress />
    </div>
  );

  const title = development ? `Homes for ${development.name}` : 'Homes';

  //Setup the interval hook to perform UI refreshes
  const props: UseIntervalHookProps = {
    identifier: `refreshHomes_${developmentId}`,
    intervalMS: 30000,
    logToConsole: true,
    onIntervalFire: refreshHomes,
    doInitialCallback: true,
    canStart: homes != null,
  };
  useIntervalAsync(props);

  return (
    <div>
      <Header title={title} />
      {homes ? homesListControl : loadingErrorControl}
    </div>
  );
};

import { TextField } from '@mgh-app/component-library';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import clsx from 'classnames';
import { FileDropzone } from 'components/FileDropzone';
import { DataCategory, FileCategory } from 'models';
import { useState } from 'react';
import {
  alignItems,
  display,
  gap,
  gridAutoRows,
  gridColumn,
  gridTemplateColumns,
  textColor,
  twCls,
} from 'style';

export interface OnUploadParams {
  dataCategory: DataCategory;
  fileCategory: FileCategory | string;
  file: File;
  documentName: string;
}

export interface UploadFileDialog {
  errorMessage?: string;
  showModal: boolean;
  onClose: () => void;
  onUpload: (params: OnUploadParams) => Promise<void>;
}

export const UploadFileDialog = ({
  errorMessage,
  showModal,
  onClose,
  onUpload,
}: UploadFileDialog) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedDataCategory, setSelectedDataCategory] = useState<DataCategory>(
    DataCategory.Logbook,
  );
  const [selectedFileCategory, setSelectedFileCategory] = useState<string | null>();
  const [documentName, setDocumentName] = useState<string>('');
  const [uploading, setUploading] = useState(false);

  const upload = async () => {
    try {
      if (selectedFileCategory && selectedFile) {
        setUploading(true);
        await onUpload({
          dataCategory: selectedDataCategory,
          fileCategory: selectedFileCategory,
          file: selectedFile,
          documentName,
        });
      }
    } finally {
      setUploading(false);
    }
  };

  return (
    <Dialog open={showModal} onClose={onClose}>
      <DialogTitle>Upload New Document</DialogTitle>
      <DialogContent>
        <div
          className={clsx(
            'min-w-[500px]',
            twCls(
              display('grid'),
              gridTemplateColumns('grid-cols-min-fc'),
              gridAutoRows('auto-rows-min'),
              gap('gap-8'),
              alignItems('items-center'),
            ),
          )}
        >
          <TextField
            className={twCls(gridColumn('col-span-2'))}
            label="Document name"
            required
            value={documentName}
            onChange={(ev) => setDocumentName(ev.target.value)}
          />
          <FileDropzone
            className={twCls(gridColumn('col-span-2'))}
            onFilesSelected={(files) => setSelectedFile(files[0])}
          />
          <TextField
            label="Data category"
            select
            required
            onChange={(ev) => setSelectedDataCategory(ev.target.value as DataCategory)}
            value={selectedDataCategory}
          >
            {Object.values(DataCategory).map((dataCategory) => (
              <MenuItem key={dataCategory} value={dataCategory}>
                {dataCategory}
              </MenuItem>
            ))}
          </TextField>
          <Autocomplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderInput={(params) => <TextField {...params} required label="File category" />}
            options={Object.values(FileCategory)}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            onChange={(_, value) => setSelectedFileCategory(value?.toLowerCase().replace(' ', '-'))}
            value={selectedFileCategory}
          />
          {errorMessage && <p className={twCls(textColor('text-red-100'))}>{errorMessage}</p>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          disabled={
            !selectedFile || !selectedDataCategory || !selectedFileCategory || !documentName
          }
          onClick={upload}
          loading={uploading}
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

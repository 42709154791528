import { get, post } from '../request';
import { baseApiUrl } from '../../config';
import { HomeUser } from 'models/homeUser';

type PostInviteUserBody = {
  email: string;
  resendInvite: boolean;
};

type PostUpdateUserBody = {
  email: string;
  id?: string;
  action: HomeUserAction;
};

export const HomeUserAction = {
  Enable: 'Enable',
  Disable: 'Disable',
  Delete: 'Delete',
} as const;
export type HomeUserAction = typeof HomeUserAction[keyof typeof HomeUserAction];

export const usersService = {
  postInviteUserToHome: async (
    developmentId: string,
    homeId: string,
    email: string,
    resendInvite: boolean,
  ): Promise<HomeUser> => {
    const body: PostInviteUserBody = { email, resendInvite };

    return post<HomeUser, PostInviteUserBody>(
      `${baseApiUrl}/developments/${developmentId}/homes/${homeId}/users/invite`,
      body,
    );
  },

  getUserForHome: async (developmentId: string, homeId: string): Promise<HomeUser[]> => {
    return get<HomeUser[]>(`${baseApiUrl}/developments/${developmentId}/homes/${homeId}/users`);
  },

  postUpdateUser: async (
    developmentId: string,
    homeId: string,
    email: string,
    action: HomeUserAction,
    id?: string,
  ): Promise<HomeUser | undefined> => {
    const body: PostUpdateUserBody = { email, id, action };

    return post<HomeUser | undefined, PostUpdateUserBody>(
      `${baseApiUrl}/developments/${developmentId}/homes/${homeId}/users`,
      body,
    );
  },
};

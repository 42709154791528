import { Header } from '../components/Header/Header';
import {
  downloadUrlConfigTemplate,
  downloadUrlBootstrapUltra,
  downloadUrlPrepareHome,
} from '../config';
import { DownloadListItem } from 'components/DownloadListItem';
import { imagesService, DownloadFileDetails } from '../services/ImagesService/imagesService';
import { useEffect, useState } from 'react';

export const DownloadsPage = () => {
  const [isoImage, setIsoImage] = useState<DownloadFileDetails>();
  const [d1Firmware, setD1Firmware] = useState<DownloadFileDetails>();

  useEffect(() => {
    (async () => {
      //Try get the ISO Image details
      try {
        const isoImageDetails = await imagesService.getImageV1();
        setIsoImage(isoImageDetails);
        console.log(`iso url: ${isoImageDetails.url}`);
      } catch (error) {
        console.error('iso url', error);
      }

      //Try get the D1 Firmware details
      try {
        const d1FirmwareDetails = await imagesService.getD1Firmware();
        setD1Firmware(d1FirmwareDetails);
        console.log(`D1 firmware url: ${d1FirmwareDetails.url}`);
      } catch (error) {
        console.error('D1 firmware url', error);
      }
    })();
  }, []);

  return (
    <div>
      <Header title="Downloads" />
      {isoImage && (
        <DownloadListItem
          url={isoImage.url}
          name="Base PC Image"
          description="The image that needs to be installed on the home PC."
          note="This image needs to be accompanied by a config.json which can be downloaded for each home
            in the app."
          updateDate={isoImage.modificationDate}
          version={isoImage.version}
        />
      )}

      {d1Firmware && (
        <DownloadListItem
          url={d1Firmware.url}
          name="D1 UltraStat Firmware"
          description="The latest firmware to be installed on a MGH D1 UltraStat Controller."
          updateDate={d1Firmware.modificationDate}
          version={d1Firmware.version}
        />
      )}

      {downloadUrlConfigTemplate && (
        <DownloadListItem
          url={downloadUrlConfigTemplate}
          name="Home Configuration Template"
          description="The latest configuration template in Excel format."
        />
      )}

      {downloadUrlBootstrapUltra && (
        <DownloadListItem
          url={downloadUrlBootstrapUltra}
          name="Bootstrap the ULTRA Core Home Server Software on a new Distribution Board"
          description="This guide is used when commissioning a new distribution board product either at the factory or onsite if it’s already been installed in a home."
        />
      )}

      {downloadUrlPrepareHome && (
        <DownloadListItem
          url={downloadUrlPrepareHome}
          name="Prepare and configure a Home for Installation of ULTRA"
          description="Use this guide when setting up a home for the first time on the MGH Admin Portal and for later changes to that configuration."
        />
      )}
    </div>
  );
};

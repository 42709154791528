import { isAuthenticated } from 'authentication/auth.aws';
import { Outlet } from 'react-router-dom';

import { DropdownMenu, Sidebar } from 'components';
import { useEffect, useState } from 'react';
import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  height,
  justifyContent,
  minWidth,
  padding,
  textColor,
  twCls,
  width,
} from 'style';
import { ReactComponent as SmallLogoIcon } from '../assets/smallLogo.svg';
import { getUserEmail } from '../authentication/auth.aws';
import { ReactComponent as LogoIcon } from '../logo.svg';
import { User } from '../models/user';

export const MainLayout = () => {
  //NOTE: No names exist on a Cognito user, only an email address.
  const [user, setUser] = useState<User>(new User('', '', ''));

  useEffect(() => {
    (async () => {
      const authResult = await isAuthenticated();
      if (authResult) {
        const email = await getUserEmail();
        const signedInUser = new User('', '', email);
        setUser(signedInUser);
      }
    })();
  }, []);

  return (
    <div style={{ minWidth: '393px' }}>
      <div
        className={twCls(
          backgroundColor('bg-grey-100'),
          height('h-20', 'lg:h-24'),
          display('flex'),
          alignItems('items-center'),
          justifyContent('justify-between'),
        )}
      >
        <div className={twCls(display('flex', 'lg:hidden'), width('w-20'), padding('pl-3'))}>
          <DropdownMenu />
        </div>
        <div
          className={twCls(
            padding('pl-5'),
            display('flex', 'lg:hidden'),
            justifyContent('justify-center'),
          )}
        >
          <SmallLogoIcon />
        </div>
        <div
          className={twCls(
            padding('pl-5'),
            display('hidden', 'lg:flex'),
            justifyContent('justify-center'),
          )}
        >
          <LogoIcon />
        </div>
        <div className={twCls(display('flex'), padding('pr-2', 'lg:pr-10'))}>
          <div
            className={twCls(
              display('hidden', 'lg:flex'),
              fontSize('text-2xl'),
              fontWeight('font-light'),
              padding('px-8'),
            )}
          >
            Welcome, {user?.displayName()}
          </div>
          <div className={twCls(width('w-20', 'lg:w-fit'))}>
            <button
              type="button"
              onClick={() => {
                // signOut();
                // navigateLogin();
              }}
              className={twCls(
                padding('px-2', 'lg:px-6', 'py-1'),
                borderRadius('rounded'),
                backgroundColor('bg-white'),
                textColor('text-blue-100'),
                fontWeight('font-medium'),
              )}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>

      <div className={twCls(display('flex'), flexDirection('flex-col', 'lg:flex-row'))}>
        <div className={twCls(display('hidden', 'lg:flex'), minWidth('lg:min-w-fit'))}>
          <Sidebar />
        </div>

        <div
          className={twCls(
            display('flex', 'lg:hidden'),
            padding('pl-4', 'pt-4'),
            fontSize('text-xl'),
            width('w-full'),
            justifyContent('justify-center'),
          )}
        >
          Admin Portal
        </div>

        <div
          className={twCls(
            width('w-full'),
            padding('px-4', 'pb-4'),
            padding('pt-6'),
            borderWidth('border-l'),
            borderColor('border-grey-300'),
          )}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

import { HomeState } from './homeState';
export type Home = {
  uuid: string;
  developmentId: string;
  homeId: string;
  name: string;
  status: HomeStatus;
  createdAt: string;
  address: Address;
  configUploadDate?: Date;
  configSyncDate?: Date;
  statusUpdateDate?: Date;
  dataUpdateDate?: Date;
  configInfo?: HomeConfigInfo;
};

export type Address = {
  country: string;
  code: string;
  city: string;
  street: string;
};

export const HomeStatus = {
  Created: 'CREATED',
  Configured: 'CONFIGURED',
  Healthy: 'HEALTHY',
  Unhealthy: 'UNHEALTHY',
} as const;
export type HomeStatus = typeof HomeStatus[keyof typeof HomeStatus];

export type HomeConfigInfo = {
  type: string;
  format: string;
  data: string;
};

export const isHomeStateUpdated = (home: Home, homeState: HomeState): boolean => {
  if (home.homeId != homeState.homeId)
    throw new Error(
      `Unable to check home state as specified home ${home.homeId} does not match specified state home ${homeState.homeId}`,
    );

  return (
    home.configSyncDate != homeState.configSyncDate ||
    home.configUploadDate != homeState.configUploadDate ||
    home.status != homeState.status ||
    home.statusUpdateDate != homeState.statusUpdateDate ||
    home.dataUpdateDate != homeState.dataUpdateDate
  );
};

export const updateHomeState = (home: Home, homeState: HomeState) => {
  if (home.homeId != homeState.homeId)
    throw new Error(
      `Unable to update home state as specified home ${home.homeId} does not match specified state home ${homeState.homeId}`,
    );

  console.log(`Updating home state ${home.homeId}`);
  home.configSyncDate = homeState.configSyncDate;
  home.configUploadDate = homeState.configUploadDate;
  home.status = homeState.status;
  home.statusUpdateDate = homeState.statusUpdateDate;
  home.dataUpdateDate = homeState.dataUpdateDate;
};

import { get } from '../request';
import { baseApiUrl } from '../../config';

export type DownloadFileDetails = {
  url: string;
  modificationDate: Date;
  version: string;
};

export const imagesService = {
  getImageV1: async (): Promise<DownloadFileDetails> => {
    return get<DownloadFileDetails>(`${baseApiUrl}/images/v1?redirect=false`);
  },

  getD1Firmware: async (): Promise<DownloadFileDetails> => {
    return get<DownloadFileDetails>(`${baseApiUrl}/firmware/switches/ultrastat?redirect=false`);
  },
};

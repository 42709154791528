import {
  twCls,
  margin,
  propertyTableStyle,
  propertyTableLabelStyle,
  propertyTableValueStyle,
  propertyTableStyleHeader,
  dataCardStyle,
  homeStatusIconStyle,
} from 'style';

import Button from '@mui/material/Button';
import {
  CloudUpload,
  CloudDownload,
  CloudSync,
  FiberManualRecord,
  SimCardDownload,
  People,
} from '@mui/icons-material';
import { getDateTimeString } from '../../utils/DateTimeUtils';
import { Home, HomeStatus } from 'models';

//Gets the home Status text and Icon color
const getHomeStatus = (status: HomeStatus): [text: string, fillColor: string] => {
  if (status == HomeStatus.Created) return ['Created', 'grey'];
  else if (status == HomeStatus.Configured) return ['Configured', 'orange'];
  else if (status == HomeStatus.Healthy) return ['Healthy', 'green'];
  else if (status == HomeStatus.Unhealthy) return ['Unhealthy', 'red'];
  else return ['Unknown', 'purple'];
};

export interface HomeListItemProps {
  home: Home;
  onClick: () => void;
  onClickUpload: () => void;
  onClickShadows: () => void;
  onClickDownloadToken: () => void;
  onClickManageUsers: () => void;
  onClickDownloadConfig: () => void;
}
export const HomeListItem = ({
  home,
  onClick,
  onClickUpload,
  onClickShadows,
  onClickDownloadToken,
  onClickManageUsers,
  onClickDownloadConfig,
}: HomeListItemProps): JSX.Element => {
  const configUploadDateString = home.configUploadDate
    ? getDateTimeString(new Date(home.configUploadDate))
    : 'Unknown';

  const configSyncDateString = home.configSyncDate
    ? getDateTimeString(new Date(home.configSyncDate))
    : 'Unknown';

  const statusUpdateDateString = home.statusUpdateDate
    ? getDateTimeString(new Date(home.statusUpdateDate))
    : 'Unknown';

  const dataUpdateDateString = home.dataUpdateDate
    ? getDateTimeString(new Date(home.dataUpdateDate))
    : 'Unknown';

  const homeStatus = getHomeStatus(home.status);

  return (
    <div className={dataCardStyle}>
      <button className={propertyTableStyleHeader} onClick={onClick}>
        {home.name} [{home.homeId}]
      </button>

      <table className={propertyTableStyle}>
        <tbody>
          <tr>
            <td className={propertyTableLabelStyle}>Status</td>
            <td className={propertyTableValueStyle}>
              {/* Setting style is required as MUI is overriding the fill color when set in CSS */}
              <FiberManualRecord
                style={{ fill: `${homeStatus[1]}` }}
                className={homeStatusIconStyle}
              />
              <span>{homeStatus[0]}</span>
            </td>
          </tr>
          <tr>
            <td className={propertyTableLabelStyle}>Last Edge Update</td>
            <td className={propertyTableValueStyle}>{statusUpdateDateString}</td>
          </tr>
          <tr>
            <td className={propertyTableLabelStyle}>Last Data Update</td>
            <td className={propertyTableValueStyle}>{dataUpdateDateString}</td>
          </tr>
          <tr>
            <td className={propertyTableLabelStyle}>Last Config Upload</td>
            <td className={propertyTableValueStyle}>{configUploadDateString}</td>
          </tr>
          <tr>
            <td className={propertyTableLabelStyle}>Last Config Sync</td>
            <td className={propertyTableValueStyle}>{configSyncDateString}</td>
          </tr>
          <tr>
            <td className={propertyTableLabelStyle}>Address</td>
            <td className={propertyTableValueStyle}>
              {home.address.street}, {home.address.city}, {home.address.country}
            </td>
          </tr>
        </tbody>
      </table>

      <div className={twCls(margin('ml-2'))}>
        <Button onClick={onClickUpload}>
          <CloudUpload className={margin('mr-1')} />
          Upload Config
        </Button>
        <Button onClick={onClickShadows}>
          <CloudSync className={margin('mr-1')} />
          Sync Config
        </Button>
        <Button onClick={onClickDownloadConfig} disabled={!home.configInfo}>
          <CloudDownload className={margin('mr-1')} />
          Download Config
        </Button>
        <Button onClick={onClickDownloadToken}>
          <SimCardDownload className={margin('mr-1')} />
          Download Token
        </Button>
        <Button
          onClick={onClickManageUsers}
          disabled={home.status != HomeStatus.Healthy && home.status != HomeStatus.Unhealthy}
        >
          <People className={margin('mr-1')} />
          Manage Users
        </Button>
      </div>
    </div>
  );
};

import {
  dataCardStyle,
  downloadDateVersionStyle,
  downloadDescriptionStyle,
  downloadLinkStyle,
  downloadSpacerStyle,
  downloadTextStyle,
} from 'style';
import { getDateTimeString } from 'utils/DateTimeUtils';

export interface DownloadListItemProps {
  url: string;
  name: string;
  description: string;
  note?: string;
  version?: string;
  updateDate?: Date;
}

export const DownloadListItem = ({
  url,
  name,
  description,
  note,
  version,
  updateDate,
}: DownloadListItemProps): JSX.Element => {
  return (
    <div>
      <div className={dataCardStyle}>
        <a href={url} rel="noreferrer" target="_blank">
          <p className={downloadLinkStyle}>{name}</p>
          <p className={downloadDescriptionStyle}>{description}</p>
          {note && <p className={downloadTextStyle}>{note}</p>}
          {(version || updateDate) && <div className={downloadSpacerStyle}></div>}
          {version && <p className={downloadDateVersionStyle}>Version: {version}</p>}
          {updateDate && (
            <p className={downloadDateVersionStyle}>
              Last updated: {getDateTimeString(new Date(updateDate))}
            </p>
          )}
        </a>
      </div>
    </div>
  );
};
